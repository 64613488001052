<template>
  <b-form-datepicker
    @input="handleDatePick"
    :placeholder="$t('applications.choose')+' '+placeholder"
    :date-format-options="datepickerFormat"
    v-model="date"
    class="date-field">
  </b-form-datepicker>
</template>

<script>
export default {
  name: 'input-date',

  props: ['placeholder'],

  data() {
    return {
      datepickerFormat: { 'year': 'numeric', 'month': 'numeric', 'day': 'numeric' },
      date: '',
    }
  },

  methods: {
    handleDatePick() {
      this.$emit('input', this.date);
    }
  }
};
</script>

<style scoped>
  @import '~bootstrap-vue/dist/bootstrap-vue.min.css';
  .date-field {
    width: 200px;
    display: inline-flex !important;
    padding: 0;
  }
</style>
