<template>
    <b-form-textarea
      v-model="text"
      :placeholder="$t('applications.type')+' '+placeholder"
      @input="handleTextareaInput"
      class="textarea-field"></b-form-textarea>
</template>

<script>
export default {
  name: 'input-textarea',
  props: ['placeholder'],

  data() {
    return {
      text: ''
    }
  },

  methods: {
    handleTextareaInput() {
      this.$emit('input', this.text);
    }
  }
};
</script>

<style scoped>
  .textarea-field {
    height: 400px;
  }
</style>
