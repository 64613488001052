<template>
  <div v-if="applicationTemplate" class="row application-form">
    <div v-if="note && note !== ''" v-html="note" class="col-12 my-4"></div>
    <div v-if="applicationTemplate.receivers && applicationTemplate.receivers.length > 1" class="col-12">
        <label for="receivers">{{$t('applications.new.application-receivers')}}</label>
        <multiselect
                id="receivers"
                v-model="receiver"
                :options="applicationTemplate.receivers"
                track-by="application_recieverID"
                :label="locale === 'ka' ? 'receiver_title' : 'receiver_titleeng'"
                :placeholder="$t('applications.new.choose-application-receivers')"
        ></multiselect>
          
    </div>
    <div v-if="!applicationTemplate.receivers || applicationTemplate.receivers.length <= 1 || receiver" class="col-12">
      <div class="template-content mt-5">
        <div class="text-right" v-if="receiver" v-html="helpers.getLocalizedField(receiver, 'head')"></div>
        <template v-for="(content, key) of templateContent">
            <span class="mb-2" v-if="content.type === 'text'" v-html="content.content"></span>
            <template v-if="content.type === 'input'">
              <input-textarea
                @input="handleTextInput($event, key, content.id)"
                v-if="getTemplateInput(content.id).type === 'textarea'"
                :placeholder="helpers.getLocalizedField(getTemplateInput(content.id), 'description')"></input-textarea>
              <input-text
                @input="handleTextInput($event, key, content.id)"
                v-if="getTemplateInput(content.id).type === 'text'"
                :placeholder="helpers.getLocalizedField(getTemplateInput(content.id), 'description')"></input-text>
              <input-date
                @input="handleTextInput($event, key, content.id)"
                v-if="getTemplateInput(content.id).type === 'date'"
                :placeholder="helpers.getLocalizedField(getTemplateInput(content.id), 'description')"></input-date>
              <input-file
                @input="handleFileInput($event, key, content.id)"
                v-if="getTemplateInput(content.id).type === 'file'"
                :placeholder="helpers.getLocalizedField(getTemplateInput(content.id), 'description')"></input-file>
              <input-select
                @input="handleSelectInput($event, key, content.id)"
                v-if="getTemplateInput(content.id).type === 'select'"
                :placeholder="helpers.getLocalizedField(getTemplateInput(content.id), 'description')"
                :data="content.id == 18 ? lectureWeekData : getTemplateInput(content.id).data"></input-select>

               

            </template>
            
        </template>
        <br><br>
        <input type="checkbox"    @click="handleCheckBox()"/><span > {{ $t('applications.new.text') }}: {{ user.email_UG }}  </span>
      </div>
      <div class="mt-4 text-center">
        <button
          v-if="!sent"
          @click="handleSendClick"
          class="btn btn-primary-red">{{ $t('applications.new.send-application') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { mapGetters } from 'vuex';
import ApplicationResource from '../../api/applicationResource';
import InputTextarea from './templates/input-textarea';
import InputText from './templates/input-text';
import InputDate from './templates/input-date';
import InputFile from './templates/input-file';
import InputSelect from './templates/input-select';

const applicationResource = new ApplicationResource();
const lectureWeekInputNumber = 18;

export default {
  name: 'application-form',

  components: { Multiselect, InputTextarea, InputText, InputDate, InputFile, InputSelect },

  props: ['application-template-id'],

  watch: {
    applicationTemplateId() {
      this.receiver = null;
      this.loadApplicationTemplate();
      this.applicationForm = new FormData();
    },

    locale() {
      window.location.reload();
    }
  },

  computed: {
    ...mapGetters({
      locale: 'language/locale',
      user:'auth/user'
    }),
    note() {
      return this.helpers.getLocalizedField(this.applicationTemplate, 'notification');
    }
  },

  mounted() {
    this.loadApplicationTemplate();
  },

  data() {
    return {
      applicationTemplate: null,
      receiver: null,
      templateContent: [],
      applicationForm: new FormData(),
      sent: false,
      lectureWeekData: [],
      checkboxChecked:false
    }
  },

  methods: {




    handleCheckBox(){

      this.checkboxChecked=!this.checkboxChecked 
    },












    handleTextInput(text, key, inputId) {




      this.applicationForm.append('inputs[' + key + '][id]', inputId);
      this.applicationForm.append('inputs[' + key + '][value]', text);
    },
    handleFileInput(file, key, inputId) {
      this.applicationForm.append('inputs[' + key + '][id]', inputId);
      this.applicationForm.append('inputs[' + key + '][value]', file);
    },
    handleSelectInput(option, key, inputId) {
      const value = this.locale === 'ka' ? option.value : option.valueeng;
      this.applicationForm.append('inputs[' + key + '][id]', inputId);
      this.applicationForm.append('inputs[' + key + '][value][id]', option.id);
      this.applicationForm.append('inputs[' + key + '][value][value]', value);

      if(this.applicationTemplateId == 50 && inputId == 2)
        this.loadLectureWeekData(option.id);


       
    },

    loadLectureWeekData(subjectId) {
      applicationResource.loadInputData(this.applicationTemplateId, lectureWeekInputNumber, subjectId).then(response => {
        this.lectureWeekData = response.data.data;
      });
    },

    handleSendClick() {
      this.applicationForm.append('template_id', this.applicationTemplateId);
      this.applicationForm.append('wantOnEmail', this.checkboxChecked);

      if(this.receiver)
        this.applicationForm.append('application_receiver_id', this.receiver.application_recieverID);

      this.sent = true;
      applicationResource.writeApplication(this.applicationForm)
      .then(response => {

        if(this.applicationTemplateId==53){
          this.helpers.notifySuccessMessage('გთხოვთ შეხვიდეთ თქვენს ug.edu.ge-ს მეილზე და გადახვიდეთ გამოგზავნილ ლინკზე რათა დაადასტუროთ განცხადების გაგზავნა / Please log in to your ug.edu.ge email and follow the link sent to confirm the application.');

        }else {
          this.helpers.notifySuccessMessage(this.$t('applications.new.success'));

        }


        

        this.applicationTemplate = null;
        this.receiver = null;
        this.templateContent = [];
        this.lectureWeekData = [];

        this.$router.push('/my-applications');
      })
      .catch(error => {
        this.sent = false;
        this.helpers.notifyErrorMessage(error);
      });
    },

    loadApplicationTemplate() {
      applicationResource.applicationTemplate(this.applicationTemplateId)
          .then(response => {
            this.applicationTemplate = response.data.data;
            this.lectureWeekData = [];

            if(this.applicationTemplate.receivers.length === 1) {
              this.receiver = this.applicationTemplate.receivers[0];
            }

            this.generateTemplateContent();
          })
    },

    getTemplateInput(id) {
      return this.applicationTemplate.inputs.find(o => o.input_number == id);
    },

    generateTemplateContent() {
      const expression = new RegExp('\\[([0-9]+]*)]','g');
      const string = this.helpers.getLocalizedField(this.applicationTemplate, 'input_text');
      let matches = [...string.matchAll(expression)];

      let templateContent = [];
      let lastPosition = 0;
      for(let match of matches) {
        templateContent.push({
          'type': 'text',
          'content': string.substring(lastPosition, match.index)
        });

        templateContent.push({
          'type': 'input',
          'id': match[1]
        });

        lastPosition = match.index + match[0].length;
      }

      if(lastPosition < string.length)
        templateContent.push({
          'type': 'text',
          'content': string.substring(lastPosition, string.length)
        });

      this.templateContent = templateContent;
    }
  }
};
</script>

<style scoped>
  .application-form {
      font-size: 14px;
  }
</style>
