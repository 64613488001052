<template>
    <div class="container-fluid">
        <div class="row">
            <sidebar></sidebar>
            <!-- MAIN CONTENT start -->
            <main role="main" class="col-12 col-sm-9 ml-sm-auto col-lg-10">
              <div class="row">
                  <div class="col-12 mb-5">
                      <app-header></app-header>
                      <page-header :page-title="pageData.title" :breadcrumb="pageData.breadcrumb"></page-header>
                  </div>
              </div>
              <section class="row application-form-container b-30 bg-light align-content-start justify-content-center px-0 mx-0 px-xl-5 mx-xl-5 mt-lg-2 mb-3">
                <div class="col-12 col-md-8 col-lg-6">
                  <label for="applicationTemplate">{{$t('applications.new.application-type')}}</label>
                  <multiselect
                    id="applicationTemplate"
                    v-model="chosenType"
                    :options="applicationTypes"
                    track-by="application_templateID"
                    :label="locale === 'ka' ? 'title' : 'titleeng'"
                    :placeholder="$t('applications.new.choose-application-type')"
                  >
                    <template slot="singleLabel" slot-scope="props">
                      <span class="option__desc">
                        <span class="option__title">{{ helpers.getLocalizedField(props.option, 'title') }}</span>
                      </span>
                    </template>
                    <template slot="option" slot-scope="props">
                      <span class="option__desc">
                        <span class="option__title">{{ helpers.getLocalizedField(props.option, 'title') }}</span>
                      </span>
                    </template>
                  </multiselect>
                </div>
                <div class="w-100"></div>
                <div class="col-12 col-md-8 col-lg-6">

                   <p class="text-danger" style="font-size:16px; font-weight:bold;" v-if="applicationType && applicationType.application_templateID==53">
                  
                        {{ $t('applications.new.urgent') }}
                  
                  </p>

                  <application-form v-if="applicationType" :application-template-id="applicationType.application_templateID"></application-form>
                  <h2 class="my-5" v-else>{{ $t('applications.new.please-choose-application-type') }}</h2>
                </div>
              </section>
            </main>
        </div>
    </div>
</template>
<script>
import sidebar from '../components/navbar/sidebar';
import appHeader from '../components/app-header';
import pageHeader from '../components/page/page-header';
import ApplicationForm from '../components/applications/application-form';
import Multiselect from 'vue-multiselect';
import ApplicationResource from '../api/applicationResource';
import { mapGetters } from 'vuex';

const applicationResource = new ApplicationResource();

export default {
  name:'new-application',
  components: { sidebar, appHeader, pageHeader, Multiselect, ApplicationForm },

  computed: {
    pageData() {
      return {
          title: this.$t('applications.new.title'),
          breadcrumb: [{
          icon: '',
          title: this.$t('home.title'),
          link: '/',
          isActive: false,
          },
            {
              icon: '',
              title: this.$t('applications.title'),
              link: '/my-applications',
              isActive: false,
            },
            {
              icon: '',
              title: this.$t('applications.new.title'),
              link: '/my-applications/new',
              isActive: true,
            }],
      };
      },
    ...mapGetters({
      locale: 'language/locale'
    })
  },

  watch:{

    chosenType(val){

         if(val.application_templateID==53){


              if(confirm("დარწმუნებული ხართ, რომ გსურთ სტუდენტის სტატუსის შეწყვეტა?სტუდენტის სტატუსის შეწყვეტის შემთხვევაში, ვერ გააგრძელებთ სწავლას საქართველოს უნივერსიტეტში./Are you certain that you desire status termination?In case of terminating your student’s status, you will not be able to continue studying at The University of Georgia.")){

                  this.applicationType=val


              }else {

                this.applicationType=null

                location.reload()

              }


         } else {
          this.applicationType=val


         }


    }


  },

  data() {
      return {
        chosenType:null,
        applicationType: null,
        applicationTypes: [],
        loading: false
      }
  },

  mounted() {
    this.loadApplicationTypes();
  },

  methods: {
    loadApplicationTypes() {
      applicationResource.applicationTemplates()
      .then(response => {
        this.applicationTypes = response.data.data;
      })
    }
  }
}
</script>
<style scoped>
  @import "~vue-multiselect/dist/vue-multiselect.min.css";
  .application-form-container {
    padding-top: 50px;
    padding-bottom: 50px;
    min-height: 600px;
  }
</style>
