<template>
  <b-form-file
    v-model="file"
    @input="handleChooseFile"
    :placeholder="$t('applications.new.choose-file')"
    class="file-field"
  ></b-form-file>
</template>

<script>
export default {
  name: 'input-file',

  data() {
    return {
      file: null
    }
  },

   methods: {
    handleChooseFile() {
      this.$emit('input', this.file);
    }
   }
};
</script>

<style scoped>
  .file-field {
    margin-top: 12px;
    display: inline-block;
    width: auto;
  }
</style>
