<template>
    <b-form-input
      type="text"
      v-model="text"
      @input="handleTextInput"
      class="text-field"
      :placeholder="$t('applications.type')+' '+placeholder"
    />
</template>

<script>
export default {
  name: 'input-text',
  props: ['placeholder'],

  data() {
    return {
      text: ''
    }
  },

  methods: {
    handleTextInput() {
      this.$emit('input', this.text);
    }
  }
};
</script>

<style scoped>
  .text-field {
    width: auto;
    display: inline-block;
  }
</style>
