<template>
  <multiselect
    @input="handleSelect"
    v-model="chosenOption"
    :options="data"
    track-by="id"
    :label="locale === 'ka' ? 'value' : 'valueeng'"
    :show-labels="false"
    class="select-field"
    :placeholder="$t('applications.choose')+' '+placeholder">
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { mapGetters } from 'vuex';

export default {
  name: 'input-select',
  props: ['placeholder', 'data'],
  components: { Multiselect },

  computed: {
    ...mapGetters({
      locale: 'language/locale'
    })
  },

  watch: {
    data() {
      this.chosenOption = '';
    }
  },

  data() {
    return {
      chosenOption: ''
    }
  },

  methods: {
    handleSelect() {
      this.$emit('input', this.chosenOption);
    }
  }
};
</script>

<style scoped>
  .select-field {
    display: inline-block;
    width: 200px;
  }
</style>
